import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

interface SEOProps {
  description?: string;
  lang?: string;
  title: string;
}

const query = graphql`
  query Meta {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

const SEO = ({ description, lang = `fr`, title }: SEOProps): JSX.Element => {
  const { site } = useStaticQuery(query);

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Brate" />
      <meta property="og:locale" content={lang} />
      <meta property="og:image" content="" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content="" />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name" : "Brate",
            "url": "https://brate.be",
            "logo": "",
            "sameAs":
            [
            "https://www.facebook.com/brate.com",
            "https://www.linkedin.com/company/brate/"
            ],
            "address":
            {
            "@type": "PostalAddress",
            "addressCountry": "",
            "addressLocality": "",
            "postalCode": "",
            "streetAddress": ""
            },
            "contactPoint":
            [{
            "@type": "ContactPoint",
            "telephone": "",
            "contactType": ""
            }]
          }
        `,
        }}
      />
    </Helmet>
  );
};

export default SEO;
